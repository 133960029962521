

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DetailTag extends Vue {
  @Prop({ required: false })
  label!: string;
  @Prop({ required: true })
  data!: string;
  @Prop({ default: "" })
  dataLink!: string;
  @Prop({ default: "string" })
  type!: string;

  imageDialogVisible = false;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_c('h3',[_vm._v(" GPS Device Registration "),(
                    _vm.gpsDevice &&
                      (_vm.role === 'dotm_staff' ||
                        _vm.role === 'admin' ||
                        _vm.role === 'gps_service_provider')
                  )?_c('el-badge',{attrs:{"value":_vm.gpsDevice.approvalStatus,"type":_vm.approvalStatusBadgeType}}):_vm._e()],1)]),_c('div',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v("Back ")])],1)]),_c('el-form',{attrs:{"label-position":'left',"label-width":"150px","enctype":"multipart/form-data","multiple":""}},[_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" General Information ")]),_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('detail-tag',{attrs:{"label":"Device Model Name","data":_vm.gpsDevice.deviceName}}),_c('detail-tag',{attrs:{"label":"Protocol","data":_vm.gpsDevice.gpsProtocolName}})],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Suportive Documents ")]),_c('el-row',[_c('el-col',{attrs:{"span":20}},[_c('detail-tag',{attrs:{"label":"NTA Approval","data":_vm.gpsDevice.ntaCertificateName,"dataLink":_vm.gpsDevice.ntaCertificate,"type":"image"}})],1)],1)],1),_c('el-row',[_c('el-col',[_c('el-form-item',{staticClass:"textRight"},[(
                      _vm.role === 'gps_service_provider' &&
                        _vm.gpsDevice.approvalStatus == 'Pending'
                    )?_c('el-button',{attrs:{"round":"","type":"primary"},on:{"click":_vm.reapply}},[_vm._v(" Reapply ")]):_vm._e(),(
                      (_vm.role === 'dotm_staff' || _vm.role === 'admin') &&
                        (_vm.gpsDevice.approvalStatus !== 'Approved' &&
                          _vm.gpsDevice.approvalStatus !== 'Pending')
                    )?_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.approveForm}},[_vm._v("Approve")]):_vm._e(),(
                      (_vm.role === 'dotm_staff' || _vm.role === 'admin') &&
                        (_vm.gpsDevice.approvalStatus !== 'Approved' &&
                          _vm.gpsDevice.approvalStatus !== 'Pending')
                    )?_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":function($event){_vm.centerRejectDialogVisible = true}}},[_vm._v("Reject")]):_vm._e(),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("Cancel")])],1)],1)],1),_c('el-dialog',{attrs:{"visible":_vm.centerRejectDialogVisible,"width":"30%","title":"Reason for reject"},on:{"update:visible":function($event){_vm.centerRejectDialogVisible=$event}}},[_c('el-form-item',{class:{
                  'is-error': _vm.fieldHasErrors('remarks'),
                  'is-required': _vm.isFieldRequired('remarks'),
                },attrs:{"label":"Remarks"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"remarks","type":"textarea","rows":2,"placeholder":"Remarks"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),(_vm.fieldHasErrors('remarks'))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.errors.first("remarks"))+" ")]):_vm._e()],1),_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onReject}},[_vm._v("Submit")])],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
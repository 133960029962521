



























































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
  JWTModule,
} from "@/store/modules";
import gpsProviderStore from "@/store/modules/gpsServiceProvider";
import { District, Palika, Protocol } from "@/store/models/meta";
import helpers from "@/utils/helpers";
import DetailTag from "@/components/UIComponents/DetailTag.vue";

@Component({
  components: {
    Input,
    Button,
    Form,
    DetailTag,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  municipality: Palika[] = [];
  districts: District[] = [];
  gpServiceId: any;
  options: Protocol[] = [] as any;
  ntaCertificate: File[] = [];
  remarks: string = "";
  role: string | undefined = "";
  public centerApproveDialogVisible = false;
  public centerRejectDialogVisible = false;
  async created() {
    let vm = this;
    this.role = helpers.getUserType();
    this.gpServiceId = commonstore.gpsServiceId;
    const id = vm.$route.query["id"];
    await metaStore.loadProtocolDD();
    if (id) {
      vm.isCreate = false;

      await gpsProviderStore.getGpsDeviceById(id);
    } else {
      gpsProviderStore.resetField();
    }
  }

  get isFileRequired() {
    if (this.$route.query.id) {
      return false;
    }
    return true;
  }

  get approvalStatusBadgeType() {
    if (this.gpsDevice) {
      const status = this.gpsDevice.approvalStatus;
      if (status == "Approved") {
        return "success";
      } else if (status == "New") {
        return "primary";
      } else if (status == "Pending") {
        return "danger";
      } else if (status == "Reapplied") {
        return "primary";
      }
    }
  }

  reapply() {
    this.$confirm(
      "You cannot edit your profile after reapplying registration. Continue?",
      "Reapply for registration",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(async () => {
        await JWTModule.reapplyGpsDevice(this.gpsDevice.id);
        this.$snotify.success("Reapplied for registration");
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Reapply canceled",
        });
      });
  }

  get ProtocolList() {
    return metaStore.protocolList;
  }
  get AdminRouter() {
    return AdminRouter;
  }

  get gpsDevice() {
    return gpsProviderStore.gpsDevice;
  }

  get profile() {
    return gpsProviderStore.profile;
  }

  approveForm() {
    let vm = this;
    this.$confirm(`Are you sure you want to Approve?`)
      .then((_) => {
        gpsProviderStore.approveDevice(this.gpsDevice.id);
        this.$snotify.success("Approved Successfully");
        this.$router.push(
          AdminRouter.GPSDeviceList + "?id=" + commonstore.gpsServiceId
        );
      })
      .catch((_) => {});
  }

  async onReject() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let vm = this;
      commonstore.showLoading();
      const id = vm.$route.query["id"];
      await gpsProviderStore.rejectDevice({ id: id, remarks: this.remarks });
      commonstore.hideLoading();
      this.$router.push(
        AdminRouter.GPSDeviceList + "?id=" + commonstore.gpsServiceId
      );
    }
  }

  handleChange(file: any, fileList: any) {
    let vm = this;
    this.ntaCertificate = [file.raw];
    vm.gpsDevice.ntaCertificate = file.raw;
  }

  handleRemove(file: any, fileList: any) {
    let vm = this;
    this.ntaCertificate = [];
    vm.gpsDevice.ntaCertificate = null;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();
    if (this.gpsDevice.id) {
      // Remove File Fields without file
      helpers.removeFilesForPatch([
        { property: this.gpsDevice, fieldName: "ntaCertificate" },
      ]);
    }
    await gpsProviderStore.createGpsDevice();
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(
      AdminRouter.GPSDeviceList + "?id=" + commonstore.gpsServiceId
    );
  }

  async cancelForm() {
    this.$router.push(AdminRouter.GPSDeviceList + "?id=" + this.gpServiceId);
  }

  mounted() {}
  updated() {}
}
